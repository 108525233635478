import React from "react";
import './App.css'
import { Routes, Route } from 'react-router-dom'

import Login from "./components/login/Login";
import Deshboard from "./components/deshboard/Deshboard";
import Users from './components/users/Users';
import Gift from "./components/gift/Gift";
import HostForm from "./components/host_form/HostForm";
import AgentForm from './components/agent_form/AgentForm';
import HelpAndSupport from './components/help_and_support/HelpAndSupport';
import WithdrawalRequest from './components/withdrawal_request/WithdrawalRequest';
import Banner from './components/banner/Banner';
import Notification from './components/notification/Notification';
import Setting from './components/setting/Setting';
import CreateBanner from './components/banner/CreateBanner';
import UserFullPage from "./components/users/UserFullPage";
import UserHistory from "./components/users/UserHistory";
import CreateGift from "./components/gift/CreateGift";
import HelpAndSupportChat from "./components/help_and_support/HelpAndSupportChat";
import HostFormFullPage from "./components/host_form/HostFormFullPage";
import AgentFormFullPage from "./components/agent_form/AgentFormFullPage";
import Home from './components/home/Home';
import Agent from "./components/agent_form/Agent";
import EditGift from "./components/gift/EditGift";
import AgentDiamondTransactions from "./components/agent_form/AgentDiamondTransactions";
import RoomBackground from "./components/room_background/RoomBackground";
import CreateRoomBackground from "./components/room_background/CreateRoomBackground";

const App = ()=>{
     const admin = document.cookie.split("=")[0];
     if(admin === 'authx'){
          return(
               <>
                    <Routes>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path="/admin-dashboard" element={<Home><Deshboard/></Home>}/>
                         <Route path="/admin-users" element={<Home><Users/></Home>}/>
                         <Route path="/admin-gifts" element={<Home><Gift/></Home>}/>
                         <Route path="/admin-host-forms" element={<Home><HostForm/></Home>}/>
                         <Route path="/admin-agent-forms" element={<Home><AgentForm/></Home>}/>
                         <Route path="/admin-help-and-support" element={<Home><HelpAndSupport/></Home>}/>
                         <Route path="/admin-withdrawal-requests" element={<Home><WithdrawalRequest/></Home>}/>
                         <Route path="/admin-banner" element={<Home><Banner/></Home>}/>
                         <Route path="/admin-notification" element={<Home><Notification/></Home>}/>
                         <Route path="/admin-setting" element={<Home><Setting/></Home>}/>
                         <Route path="/admin-create-banner" element={<Home><CreateBanner/></Home>}/>
                         <Route path="/admin-user-profile" element={<Home><UserFullPage/></Home>}/>
                         <Route path="/admin-user-history" element={<Home><UserHistory/></Home>}/>
                         <Route path="/admin-create-gift" element={<Home><CreateGift/></Home>}/>
                         <Route path="/admin-help-and-support-chat" element={<Home><HelpAndSupportChat/></Home>}/>
                         <Route path="/admin-host-form-full" element={<Home><HostFormFullPage/></Home>}/>
                         <Route path="/admin-agent-form-full" element={<Home><AgentFormFullPage/></Home>}/>
                         <Route path="/admin-agent" element={<Home><Agent/></Home>}/>
                         <Route path="/admin-edit-gift" element={<Home><EditGift/></Home>}/>
                         <Route path="/admin-agent-d-transactions" element={<Home><AgentDiamondTransactions/></Home>}/>
                         <Route path="/admin-room-backgrounds" element={<Home><RoomBackground/></Home>}/>
                         <Route path="/admin-create-room-backgrounds" element={<Home><CreateRoomBackground/></Home>}/>
                         {/* <Route path="/admin-user-withdrawal-history" element={<Home><UserWithdrawalHistory/></Home>}/> */}
                         {/* <Route path="/admin-withdrawal-history" element={<Home><WithdrawalHistroy/></Home>}/> */}
                         {/* <Route path="/admin-user-network" element={<Home><Network/></Home>}/> */}
                         {/* <Route path="/admin-user-data" element={<Home><UserPage/></Home>}/> */}
                         {/* <Route path="/admin-upload-banner" element={<Home><UploadBanner/></Home>}/> */}
                         {/* <Route path="/admin-setting" element={<Home><Setting/></Home>}/> */}
                         {/* <Route path="/admin-w-list" element={<Home><WithdrowList/></Home>}/> */}
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
     else{
          return(
               <>
                    <Routes>
                         <Route path="/admin-login" element={<Login/>}/>
                         {/* <Route path="/admin-dashboard" element={<Login/>}/>
                         <Route path="/admin-notification" element={<Login/>}/>
                         <Route path="/admin-payment-history" element={<Login/>}/>
                         <Route path="/admin-user-withdrawal-history" element={<Login/>}/>
                         <Route path="/admin-user-network" element={<Login/>}/>
                         <Route path="/admin-upload-banner" element={<Login/>}/>
                         <Route path="/admin-user-data" element={<Login/>}/>
                         <Route path="/admin-withdrawal-history" element={<Login/>}/>
                         <Route path="/admin-setting" element={<Login/>}/> */}
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
}

export default App;